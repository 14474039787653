import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCart } from './CartContext';
import './Main.css';


function Main() {
  const API_BASE_URL = 'http://localhost:5000';
  const { cartItems, addToCart, removeFromCart, updateQuantity } = useCart();
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [orderType, setOrderType] = useState('delivery');
  const [accountExpanded, setAccountExpanded] = useState(false);
  const bannerContainerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState('home');
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const location = useLocation();
  const [queueTimes, setQueueTimes] = useState({});

  const banners = [
    {
      img: "images/banner1.png",
      title: "Exclusive offers for all NJIT students",
      description: "5% off on your first order use code: NEWUSERS",
      button: { text: "Order Now", link: "/order", class: "maroon-btn" }
    },
    {
      img: "images/banner 2.png",
      title: "Food Trucks and More",
      description: "Grab yours without any waiting",
      button: { text: "Check Queue", link: "/queue", class: "blue-btn" }
    },
    {
      img: "images/banner3.png",
      title: "Free Delivery on group orders Above $50",
      description: "Wait less, save more",
      button: { text: "Order Now", link: "/order", class: "green-btn" }
    },
    {
      img: "images/banner4.png",
      title: "Free Delivery on all orders with Cravepass",
      description: "Starting from $2.99/month",
      button: { text: "Learn More", link: "/martpass-info", class: "orange-btn" }
    }
  ];

  useEffect(() => {
    if (location.state && location.state.message) {
      setMessage(location.state.message);
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [location]);

 {/* useEffect(() => {
    fetchQueueTimes();
  }, []);*/}

 {/* const fetchQueueTimes = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/queue-times');
      setQueueTimes(response.data);
    } catch (error) {
      console.error('Error fetching queue times:', error);
    }
  };

  const updateQueueTime = async (truckId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/queue-time/${truckId}`);
      console.log(`Updated queue time for ${truckId}:`, response.data);
      // Update your state or context with the new queue time
      // For example:
      setQueueTimes(prevTimes => ({...prevTimes, [truckId]: response.data.estimated_wait_time}));
    } catch (error) {
      console.error('Error updating queue time:', error);
    }
  };*/}

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };
  
  const handleCartClick = () => {
    navigate('/cart');
  };

  const closeSideNav = (e) => {
    if (e.target.classList.contains('overlay')) {
      setSideNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeSideNav);
    return () => {
      document.removeEventListener('click', closeSideNav);
    };
  }, []);

  const nextBanner = useCallback(() => {
    setCurrentBannerIndex((prevIndex) => (prevIndex + 2) % banners.length);
  }, [banners.length]);

  const prevBanner = useCallback(() => {
    setCurrentBannerIndex((prevIndex) => (prevIndex - 2 + banners.length) % banners.length);
  }, [banners.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        nextBanner();
      }
    }, 5000); // Change banner every 5 seconds
    return () => clearInterval(interval);
  }, [isHovered, nextBanner]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="MainPage">
      {message && <div className="success-message">{message}</div>}
      <section className="menu">
        <nav className="nav">
          <div className="nav-top">
            <div className="hamburger-menu" onClick={toggleSideNav}>
              <img src="images/user-icon.png" alt="Menu" />
            </div>
            <div className="logo">
              <h1>Uni<b>Hub</b></h1>
            </div>
            <div className="order-type-container">
              <button className="order-type-button">
                <span className="delivery">Delivery</span>
                <span className="pickup">Pickup</span>
              </button>
            </div>
            <div className="location-icon">
              <img src="images/location-icon.png" alt="Location" />
            </div>
            <div className="search-container">
              <button className="search-button">
                <img src="images/search-icon.png" alt="Search" />
                <span>Search your Mart</span>
              </button>
            </div>
            <div className="cart-icon" onClick={handleCartClick}>
              <img src="images/cart-icon.png" alt="Cart" />
              {cartItems.length > 0 && (
                <span className="cart-count">{cartItems.length}</span>
              )}
            </div>
          </div>
          <ul>
            <li><Link className="active" to="/main">Home</Link></li>
            <li><Link to="/queue-stats">Queue</Link></li>
            <li><Link to="/events">Events</Link></li>
            <li><Link to="/blogs">Blogs</Link></li>
            <li><Link to="/delivery">Delivery</Link></li>
          </ul>
          <div className="filter-buttons">
            <button className="filter-btn offers-btn">
              <img src="images/offer-icon.png" alt="Offers" />
              <span>Offers</span>
            </button>
            <button className="filter-btn rating-btn">
              <img src="images/rating-icon.png" alt="Rating" />
              <span>Rating</span>
            </button>
            <button className="filter-btn delivery-fee-btn">
              <span>Delivery fee</span>
              <img src="images/deliveryfee-icon.png" alt="Delivery Fee" />
            </button>
            <button className="filter-btn price-btn">
              <span>Price</span>
              <img src="images/price-icon.png" alt="Price" />
            </button>
            <button className="filter-btn sort-btn">
              <span>Sort</span>
              <img src="images/sort-icon.png" alt="Sort" />
            </button>
          </div>
        </nav>
        <div className="banners-container" 
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave} 
             ref={bannerContainerRef}>
          <button className="banner-nav-btn prev-btn" onClick={prevBanner}>&lt;</button>
          <div className="banners"
               style={{ 
                 transform: `translateX(-${currentBannerIndex * 25}%)`,
                 transition: isHovered ? 'none' : 'transform 0.5s ease'
               }}>
            {banners.map((banner, index) => (
              <div key={index} className="banner">
                <img src={banner.img} alt={`Banner ${index + 1}`} />
                <div className="banner-content">
                  <h3>{banner.title}</h3>
                  <p>{banner.description}</p>
                  <div className="banner-buttons">
                    <Link to={banner.button.link} className={`banner-btn ${banner.button.class}`}>{banner.button.text}</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="banner-nav-btn next-btn" onClick={nextBanner}>&gt;</button>
        </div>
      </section>

      <div style={{ height: '2em' }}></div>

      <section className="food-trucks-section">
        <h2>Food Trucks</h2>
        <div className="tile-container">
          <RestaurantTile
            id="tajmahal_truck"
            name="Taj Mahal Food Truck"
            image="images/food-trucks-image.jpeg"
            rating={4.8}
            deliveryTime="15-25 min"
            deliveryFee="$1.99"
            link="/Tajmahal" 
            queueTime="15-20"
          />
          {/* Add more food truck tiles as needed */}
        </div>
      </section>

      <div style={{ height: '1em' }}></div>
      <section className="cafes-section">
        <h2>Cafes</h2>
        <div className="tile-container">
          <RestaurantTile
            id="intrinsic_cafe"
            name="Intrinsic Cafe"
            image="images/cafe-image.jpg"
            cuisine="Coffee, Pastries"
            rating={4.8}
            deliveryTime="10-20 min"
            deliveryFee="$1.49"
            queueTime="15-20"
          />
        </div>
      </section>

      <div className={`overlay ${sideNavOpen ? 'open' : ''}`}></div>
      <div className={`side-nav ${sideNavOpen ? 'open' : ''}`}>
        <div className="side-nav-content">
          <ul>
            <li><Link to="/account"><img src="images/account-icon.png" alt="Account" />Account</Link></li>
            <li><Link to="/orders"><img src="images/order-icon.png" alt="Orders" />Orders</Link></li>
            <li><Link to="/favorites"><img src="images/favorite-icon.png" alt="Favorites" />Favorites</Link></li>
            <li><Link to="/promotions"><img src="images/promotions-icon.png" alt="Promotions" />Promotions</Link></li>
            <li><Link to="/gift-cards"><img src="images/giftcard-icon.png" alt="Gift Cards" />Gift Cards</Link></li>
            <li><Link to="/refer"><img src="images/refer-icon.png" alt="Refer & Earn" />Refer & Earn</Link></li>
            <li><Link to="/help"><img src="images/help-icon.png" alt="Help" />Help</Link></li>
            <li><Link to="/"><img src="images/signout-icon.png" alt="Sign Out" />Sign Out</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function RestaurantTile({ id, name, image, cuisine, rating, deliveryTime, deliveryFee, link = null, queueTime }) {
  const Content = (
    <>
      <img src={image} alt={name} className="restaurant-image" />
      <div className="restaurant-info">
        <h3 className="restaurant-name">{name}</h3>
        {cuisine && <p className="restaurant-cuisine">{cuisine}</p>}
        <div className="restaurant-details">
          <span className="restaurant-rating">
            <span className="star-icon">★</span>
            {rating}
          </span>
          <span className="restaurant-delivery-time">{deliveryTime}</span>
          <span className="restaurant-delivery-fee">{deliveryFee}</span>
          {/*<QueueTimeDisplay truckId={id} queueTime={queueTime} />*/}
        </div>
      </div>
    </>
  );

  return link ? (
    <Link to={link} className="restaurant-tile">
      {Content}
    </Link>
  ) : (
    <div className="restaurant-tile">
      {Content}
    </div>
  );
}

export default Main;